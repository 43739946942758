@import "core/variables";
footer {
  display: flex;
  flex-direction: column;
  .footer-columns {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    @media screen and (min-width: $resolutionDesktop) {
      flex-direction: row;
      padding: 0;
    }
  }
  .category-wrapper {
    padding: 0.75em 0 1em;
    .header-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .heading3 {
        font-weight: 600;
      }
      .column-name {
        cursor: pointer;
        @include heading3;
        @include font-style-semi;
        @media screen and (min-width: $resolutionDesktop) {
          cursor: unset;
          @include tiny-text;
          @include uppercase;
          color: $label;
        }
      }
    }
    .arrow-wrapper {
      display: block;
    }
    .single-link {
      display: none;
    }
    &.open {
      .single-link {
        display: block;
        margin-top: 1.25em;
      }
    }
  }
  @media screen and (min-width: $resolutionDesktop) {
    flex-direction: row;
    padding: 4em 0 0;

    .category-wrapper {
      width: 33.3%;
      .header-wrapper {
        margin-bottom: 2.5em;
      }
      .arrow-wrapper {
        display: none;
      }
      .single-link {
        display: block;
        margin-bottom: 27px;

        a {
          @include font-style-light();
        }
      }
    }
  }
}

.bottom-page-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
  background-color: $primaryBackground;
  @media screen and (min-width: $resolutionDesktop) {
    flex-direction: row;

    app-newsletter {
      order: 2;
      width: 25%;
    }
    app-footer {
      order: 1;
      width: 75%;
    }
  }
  .separator-gray {
    display: block;

    @media screen and (min-width: $resolutionDesktop) {
      display: none;
    }
  }
}

.bottom-caption-footer {
  display: flex;
  justify-content: center;
  padding: 35px 0;
  color: $bodyText;
  @media screen and (min-width: $resolutionDesktop) {
    justify-content: space-between;
  }

  .phones {
    display: none;
    @media screen and (min-width: $resolutionDesktop) {
      display: block;
    }
  }
}
