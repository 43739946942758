@font-face {
    font-family: Arte;
    src: url("/assets/font/arte-icons-filters.ttf");
}
@font-face {
    font-family: ArteNawigacja;
    src: url("/assets/font/arte-icons-navigation.ttf");
}

.ai {
    font-family: ArteNawigacja;
    font-size: 30px;
    font-style: normal;
    &.ai-search {
        &::before {
            content: "\e016";
        }
    }
    &.ai-account {
        &::before {
            content: "\e000";
        }
    }
    &.ai-heart {
        &::before {
            content: "\e00c";
        }
    }
    &.ai-cart {
        &::before {
            content: "\e00A";
        }
    }
    &.ai-pin {
        &::before {
            content: "\e013";
        }
    }
    &.ai-phone {
        &::before {
            content: "\e012";
        }
    }
    &.ai-advisor {
        &::before {
            content: "\e001";
        }
    }
    &.ai-txt-cloud {
        &::before {
            content: "\e018";
        }
    }
    &.ai-write-form {
        &::before {
            content: "\e019";
        }
    }
    &.ai-mail {
        &::before {
            content: "\e00f";
        }
    }
    &.ai-order {
        &::before {
            content: "\e011";
        }
    }
    &.ai-back {
        &::before {
            content: "\e003";
        }
    }
    &.ai-back-bold {
        &::before {
            content: "\e004";
        }
    }
    &.ai-next {
        &::before {
            content: "\e005";
        }
    }
    &.ai-next-bold {
        &::before {
            content: "\e006";
        }
    }
    &.ai-top {
        &::before {
            content: "\e007";
        }
    }
    &.ai-bottom {
        &::before {
            content: "\e002";
        }
    }
    &.ai-tick {
        &::before {
            content: "\e017";
        }
    }
    &.ai-question-mark {
        &::before {
            content: "\e015";
        }
    }
    &.ai-cancel {
        &::before {
            content: "\e008";
        }
    }
    &.ai-instagram {
        &::before {
            content: "\e00d";
        }
    }
    &.ai-facebook {
        &::before {
            content: "\e009";
        }
    }
    &.ai-pinterest {
        &::before {
            content: "\e014";
        }
    }
    &.ai-google {
        &::before {
            content: "\e072";
        }
    }
    &.ai-navigation {
        &::before {
            content: "\e010";
        }
    }
    &.ai-crossair {
        &::before {
            content: "\e073";
        }
    }
    &.ai-star {
        &::before {
            content: "\e074";
        }
    }
    &.ai-switch-row {
        &::before {
            content: "\e075";
        }
    }
    &.ai-switch-row3 {
        &::before {
            content: "\e076";
        }
    }
    &.ai-switch-column {
        &::before {
            content: "\e077";
        }
    }
    &.ai-menu {
        &::before {
            content: "\e078";
        }
    }
}

.aif {
    font-family: Arte;
    font-size: 30px;
    font-style: normal;

    &.ai-order-date {
        &::before {
            content: "\e041";
        }
    }
    &.ai-delivery {
        &::before {
            content: "\e042";
        }
    }
    &.ai-shipment {
        &::before {
            content: "\e040";
        }
    }
}
