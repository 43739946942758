$light-weight: 300;
$book-weight: 400;
$semi-weight: 600;

$mainColor: #b52926;
$textLink: #64bba6;

//heading 1-6
$heading: #222222;
//body text small text tiny caption
$bodyText: #444444;
$label: #888888;
$grayFooterHeader: #888888;
$border: #cccccc;
$border-grey: #939393;
$inactiveElement: #f1f1f1;
$primaryBackground: #ffffff;
$specialColorForOneOFSeparatorGray: #dfe0e0;
$specialColorForBackgroundStars: #bee6e9;
$placeholder-dark: #e9e9e9;
$placeholder-light: #f4f4f4;
$placeholder-lighten: #f8f8f8;

//box-shadow: 0px 0px 5px rgba(34, 34, 34, 0.2); ???

$errorColor: #b52926;
$warning: #edc252;
$info: #4aa3d6;
$success: #64bba6;
$primary-button-hover: #83c9b8;

$shadow1: 0px 0px 5px rgba(34, 34, 34, 0.2);
$shadow2: 0px 0px 18px rgba(34, 34, 34, 0.2);
$shadow3: 0px 0px 48px rgba(34, 34, 34, 0.2);
$shadow4: 0px 0px 8px rgba(34, 34, 34, 0.4);
$shadow5: 0px 0px 18px rgba(0, 0, 0, 0.4);
$shadow6: 0px 0px 48px rgba(34, 34, 34, 0.4);

$focus: 0px 0px 8px rgba(0, 117, 255, 0.6);
$focus-border: 1px solid rgba(0, 117, 255, 0.6);
$hover-light: rgb(78, 78, 78);
$hover-dark: rgba(34, 34, 34, 0.2);

$resolutionMiniMobile: 320px;
$resolutionMinMidMobile: 420px;
$resolutionMidMobile: 550px;
$resolutionTablet: 768px;
$resolutionTabletMinus: 767px;
$resolutionMidTablet: 1024px;
$resolutionDesktopMinus: 1279px;
$resolutionDesktop: 1280px;
$resolutionMinLaptop: 1366px;
$resolutionMidLaptop: 1536px;
$resolutionHalfHd: 1680px;
$resolutionHalfHdMinus: 1679px;
$resolutionFullHd: 1880px;
//when want to change, change values in resolutions.model.ts also !important

$mobileContainerPadding: 16px;
$tabletContainerPadding: 24px;
$desktopContainerPadding: 48px;
$fullHdContainerPadding: 64px;

//classes{
@mixin heading1() {
  line-height: 58px;
  font-size: 48px;
  font-weight: 600;
}

@mixin heading2() {
  line-height: 42px;
  font-size: 32px;
  font-weight: 600;
}

@mixin heading3() {
  line-height: 34px;
  font-size: 24px;
  font-weight: 600;
}

@mixin heading4() {
  line-height: 34px;
  font-size: 24px;
  font-weight: 400;
}

@mixin heading5() {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
}

@mixin heading6() {
  line-height: 30px;
  font-size: 20px;
  font-weight: 400;
}

@mixin separator1() {
  margin-bottom: 1em !important;
}

@mixin separator-one-half() {
  margin-bottom: 1.5em !important;
}

@mixin separator2() {
  margin-bottom: 2em !important;
}

@mixin separator3() {
  margin-bottom: 3em !important;
}

@mixin separator4() {
  margin-bottom: 4em !important;
}

@mixin separator5() {
  margin-bottom: 5.5em !important;
}

@mixin font-style-light() {
  font-weight: $light-weight;
}

@mixin font-style-book() {
  font-weight: $book-weight;
}

@mixin font-style-semi() {
  font-weight: $semi-weight;
}

@mixin no-uppercase() {
  text-transform: inherit;
}

@mixin tiny-text() {
  line-height: 16px;
  font-size: 12px;
}

@mixin body-text() {
  line-height: 26px;
  font-size: 16px;
  color: $bodyText;
}

@mixin normal-text() {
  line-height: 26px;
  font-size: 16px;
  @include font-style-book();
}

@mixin button-disabled() {
  background-color: $border;
}

@mixin uppercase() {
  text-transform: uppercase;
}

@mixin card-border() {
  border: 1px solid $border;
  border-radius: 8px;
  padding: 2em;
  margin-bottom: 1em;
  position: relative;
  p {
    font-weight: $light-weight;
    color: $bodyText;
    &:nth-child(1) {
      font-weight: $semi-weight;
      color: $heading;
      margin: 0;
    }
    &.success-link {
      color: $success;
    }
  }
}
