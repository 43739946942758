.flag {
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $light-weight;
  white-space: nowrap;
  &.flag-success {
    color: $success;
    border: 2px solid $success;
  }
  &.flag-error {
    color: $mainColor;
    border: 2px solid $mainColor;
  }
  &.flag-error-small {
    border: 1px solid $mainColor;
    text-transform: uppercase;
    padding: 4px 8px;
  }
}
