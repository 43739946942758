@import "core/variables";

.alert {
  width: 100%;
  color: $heading;
  position: relative;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 14px 52px 14px 0;
  &.Success {
    border: 1px solid $success;
    .mark {
      &::after {
        content: "\e017";
        color: $success;
      }
    }
  }
  &.Warning {
    border: 1px solid $warning;
    .mark {
      background-image: url("/assets/icons/alert-24-triangle-orange.svg");
    }
  }
  &.Error {
    border: 1px solid $errorColor;
    .mark {
      background-image: url("/assets/icons/alert-mark-red.svg");
    }
  }
  &.Info {
    border: 1px solid $info;
    .mark {
      background-image: url("/assets/icons/info-blue.svg");
    }
  }
  .error-text {
    margin-left: 64px;
    @media screen and (max-width: $resolutionTabletMinus) {
      padding: 1em 0;
    }
    @include font-style-semi();
  }
  .mark {
    position: absolute;
    width: 22px;
    height: 22px;
    font-size: 22px;
    font-family: ArteNawigacja;
    left: 24px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .cancel-mark {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 24px;
    display: flex;
    cursor: pointer;
    &::before {
      content: "\e008";
      font-family: ArteNawigacja;
      font-size: 12px;
    }
  }
}
