@import "core/variables";

.cart-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
}

.navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 65px;
  padding: 1.25em 0;
  z-index: 4;
  @media screen and (min-width: $resolutionDesktop) {
    z-index: 10;
  }

  .left-part,
  .right-part,
  .icons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .panel-above {
      user-select: none;
      display: none;
    }

    .logo-wrapper {
      user-select: none;

      a {
        display: flex;
        width: 62px;
        @media screen and (min-width: $resolutionDesktop) {
          width: 125px;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .menu {
    display: none;
    @media screen and (min-width: $resolutionDesktop) {
      display: block;
    }

    .img-wrapper {
      img {
        width: 100%;
      }
    }

    .sticky-header-menu {
      display: none;
    }
  }

  .not-menu-wrapper {
    display: none;
  }

  .icons-wrapper {
    gap: 5px;
    @media screen and (min-width: $resolutionTablet) {
      gap: 10px;
    }
    @media screen and (min-width: $resolutionDesktop) {
      gap: 15px;
    }
    > div {
      img {
        width: 100%;
      }
    }

    .header-icon {
      display: flex;
      cursor: pointer;
      position: relative;
      width: 43px;
      height: 43px;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: $resolutionTablet) {
        min-width: 36px;
        width: 36px;
      }
      @media screen and (min-width: $resolutionDesktop) {
        width: 60px;
        height: 60px;
      }
      .icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
        @media screen and (min-width: $resolutionDesktop) {
          font-size: 30px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .favourite,
    .cart {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-icon,
    .account-icon {
      display: none;
    }

    .favourite {
      .cart-items-number {
        display: none;
      }
      @media screen and (min-width: $resolutionDesktop) {
        .cart-items-number {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: $resolutionTablet) {
    .right-part,
    .icons-wrapper {
      width: 100%;
      z-index: 5;
      background-color: transparent;
      @media screen and (min-width: $resolutionHalfHd) {
        min-width: 544px;
      }

      .search {
        width: inherit;
        height: 4em;
        background-color: transparent;
        margin-right: 7px;

        .input-search-wrapper {
          background-color: transparent;
        }
      }
    }
    height: 80px;
    padding: 0;
    align-items: center;
    .menu,
    .logo-wrapper {
      margin-right: 32px;
      @media screen and (min-width: $resolutionDesktop) {
        margin-right: 25px;
      }
      @media screen and (min-width: $resolutionMinLaptop) {
        margin-right: 32px;
      }
    }
  }

  @media screen and (min-width: $resolutionDesktop) {
    height: 144px;
    align-items: flex-end;
    padding: 1.5em 0 30px;
    .left-part {
      width: 75%;
      z-index: 5;

      .not-menu-wrapper {
        :nth-child(1) {
          margin-right: 30px;
        }
      }
    }
    .right-part {
      width: 300px;
      @media screen and (min-width: $resolutionMinLaptop) {
        width: 400px;
      }
      @media screen and (min-width: $resolutionMidLaptop) {
        width: 485px;
      }
      @media screen and (min-width: $resolutionHalfHd) {
        width: 660px;
      }
      display: flex;
      flex-direction: column;
      height: 98px;

      .panel-above {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .element-wrapper {
          display: flex;
          position: relative;
          align-items: center;
          margin: 8px 0px;

          .link {
            padding: 8px 12px 18px;
            background: none;
            border: none;
            cursor: pointer;
            @media screen and (min-width: $resolutionMinLaptop) {
              padding: 8px 16px 18px;
            }
            &:active,
            &:focus {
              outline: none;
            }

            .cart-items-number {
              top: 1px;
            }
          }

          .tiny-text {
            margin-left: 12px;
            &:not(.cart-above) {
              display: none;
            }

            @media screen and (min-width: $resolutionMidLaptop) {
              &.pos {
                display: block;
              }
            }
            @media screen and (min-width: $resolutionHalfHd) {
              display: block !important;
            }
          }
        }

        > div {
          margin-right: 1em;
          white-space: nowrap;
          height: 1.5em;
          @media screen and (min-width: 1530px) {
            margin-right: 2em;
          }
        }

        .image-wrapper {
          margin-bottom: 0;
          position: relative;
          display: flex;
          align-items: center;

          .ai {
            width: 21px;
            height: 21px;
            font-size: 21px;
            margin-bottom: 5px;
          }
        }
      }

      .icons-wrapper {
        .favourite,
        .cart {
          display: none;
        }

        .search {
          margin-right: 0;
          margin-top: 8px;
          padding-right: 0;
        }
      }
    }
    .logo-wrapper {
      z-index: 5;
      width: 125px;

      a {
        height: 50px;
        width: 125px;
        margin-right: 2em;
      }
    }
    .not-menu-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 24px;
    }
  }
}

.menu-hamburger {
  cursor: pointer;
  margin: 0 0px 0 -15px;
  padding: 15px;
  display: flex;
  @media screen and (min-width: $resolutionMiniMobile) {
    margin: 0 10px 0 -15px;
  }
  @media screen and (min-width: $resolutionDesktop) {
    display: none;
  }
  .ai-menu {
    display: block;
    height: 24px;
    width: 24px;
    font-size: 24px;
    line-height: 24px;
  }
}

nav {
  height: 64px;
  @media screen and (min-width: $resolutionTablet) {
    height: 80px;
  }
  @media screen and (min-width: $resolutionDesktop) {
    height: 145px;
  }

  app-navbar {
    display: block;
    background-color: $primaryBackground;
    position: relative;

    .hidden {
      display: none;
    }

    &.main-view {
      border-bottom: 1px solid $primaryBackground;

      @media screen and (max-width: $resolutionDesktop) {
        border-bottom: 1px solid $border;
      }
    }

    &.not-main-view,
    &.cart-navbar {
      border-bottom: 1px solid $border;
    }

    @media screen and (min-width: $resolutionDesktop) {
      .navbar-wrapper .right-part .navbar-search .cancel-button {
        display: none;
      }
    }

    &.fixed {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 3;
      border-bottom: 1px solid $border;
      @media screen and (min-width: $resolutionDesktop) {
        z-index: 14;
      }

      .navbar-wrapper {
        ::ng-deep {
          app-search {
            .input-search-wrapper.open {
              .search-prompt.wider {
                max-width: none;
              }
            }
          }
        }
      }

      .filters-list-desktop {
        top: 56px;
      }

      @media screen and (min-width: $resolutionDesktop) {
        top: -64px;
        @media screen and (min-width: $resolutionFullHd) {
          left: 0;
        }
        &.cart-navbar {
          top: -20px;
          &.final-step {
            top: -64px !important;
          }
        }
        .navbar-wrapper {
          padding-bottom: 17px;

          &.cart-page {
            height: 98px;
          }

          .right-part {
            height: 40px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .panel-above {
              display: none;
            }

            .navbar-search {
              display: none;

              &.visible {
                display: block;
                position: absolute;
                left: -620px;
                width: 150%;
                top: -6px;

                app-search {
                  section {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;

                    .input-search-wrapper {
                      width: 100%;
                    }
                  }
                }

                .cancel-button {
                  cursor: pointer;
                  font-size: 18px;
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  top: 10px;
                  right: -34px;
                  display: block;
                }
              }
            }

            .favourite,
            .cart,
            .search-icon,
            .account-icon {
              display: flex;

              &.hidden {
                display: none;
              }
            }

            .icons-wrapper {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }

          .left-part {
            .menu {
              &.is-product-page {
                app-menu {
                  display: none;
                }

                .sticky-header-menu {
                  display: flex;
                  align-items: center;
                  margin-left: 40px;

                  a {
                    cursor: pointer;
                    @include heading5();
                    @include font-style-semi();
                    margin-right: 65px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.border-line {
  display: none;
}

body.fixed-class {
  .not-main-view {
    .border-line {
      @media screen and (min-width: 1918px) {
        height: 1px;
        background-color: $border;
        width: 150vw;
        position: absolute;
        left: -50vw;
        right: 50vw;
        top: 143px;
        display: block;
      }
    }
  }
  @media all and (-ms-high-contrast: none) and (min-width: $resolutionFullHd+40), (-ms-high-contrast: active) {
    left: 10% !important;
    right: 10% !important;
  }
}

.help-section-wrapper {
  display: none;
}

.steps-wrapper {
  display: flex;

  @media screen and (min-width: $resolutionTablet) {
    width: 100%;
    justify-content: flex-end;
  }

  .horizontal-separator {
    display: flex;
    align-items: center;
    background-color: $inactiveElement;
    height: 1px;
    margin: auto 6px;
    width: 8px;
    @media screen and (min-width: $resolutionTablet) {
      width: 32px;
    }
  }

  .step-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media screen and (min-width: $resolutionDesktop) {
      margin-left: 6px;
      margin-right: 0;
      height: 48px;
    }
    @media screen and (min-width: $resolutionMinLaptop) {
      margin-left: 36px;
      margin-right: 36px;
    }
    @media screen and (min-width: $resolutionMidLaptop) {
      margin-left: 40px;
      margin-right: 40px;
    }

    .extended-text-tablet {
      @include tiny-text();
      color: $bodyText;
      margin-left: 6px;
      @media screen and (min-width: $resolutionTablet) {
        margin-left: 8px;
      }
      @media screen and (min-width: $resolutionDesktop) {
        @include body-text();
        min-width: 130px;
      }
      @media screen and (min-width: $resolutionMidLaptop) {
        @include heading6();
      }

      span {
        display: none;
        @media screen and (min-width: $resolutionTablet) {
          display: inline-block;
        }
      }

      &.desktop {
        span {
          display: none;
          @media screen and (min-width: $resolutionDesktop) {
            display: inline-block;
          }
        }
      }
    }

    .button {
      border: 2px solid $inactiveElement;
      color: $inactiveElement;
      @include tiny-text();
      @include font-style-light();
      width: 30px;
      height: 30px;
      padding-top: 2px;
      margin-right: 0px;
      @media screen and (min-width: $resolutionTablet) {
        margin-right: 0.5em;
      }
      @media screen and (min-width: 1380px) {
        width: 40px;
        height: 40px;
      }
      @media all and (min-width: $resolutionTablet) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-top: 6px;
        margin-bottom: 4px;
      }
    }

    p {
      color: $inactiveElement;
      display: none;
      margin-left: 6px;
      @media screen and (min-width: $resolutionTablet) {
        display: block;
      }
      @media screen and (min-width: $resolutionTablet) {
        margin-left: 16px;
      }
    }

    &.active {
      .button {
        border: 2px solid $success;
        color: $bodyText;
      }

      p {
        display: none;
        color: $heading;
        @media screen and (min-width: $resolutionMiniMobile) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: $resolutionDesktop) {
  .cart-page {
    height: 112px;
    .left-part {
      width: auto;
    }

    .right-part {
      height: auto;
      width: 100%;
      flex-direction: row;

      .help-section-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 200px;
        margin-left: auto;
        @include tiny-text();
        @include font-style-light();

        p {
          margin: 0;

          &:nth-child(2) {
            margin-top: 4px;
          }
        }
      }

      .steps-wrapper {
        justify-content: center;
      }
    }
  }
}

.cart {
  position: relative;
  .cart-items-number {
    position: absolute;
    top: 8px;
    left: 16px;
    width: 16px;
    text-align: center;
    @media screen and (min-width: $resolutionTablet) {
      left: 12px;
    }
    @media screen and (min-width: $resolutionDesktop) {
      position: static;
    }
  }
}

.element-wrapper,
.cart {
  .cart-items-number {
    &.upper {
      position: absolute;
      top: -1px;
      right: 4px;
      @media screen and (min-width: $resolutionDesktop) {
        position: static;
        font-size: 12px;
      }
    }
  }
}

.favourite-element-wrapper {
  &.animation {
    animation: blink 1s;
  }

  &.stop-animation {
    animation: none;
  }
  .cart-items-number.upper {
    display: none;
    @media screen and (min-width: $resolutionDesktop) {
      display: block;
      min-width: 15px;
    }
  }
}

.favourite {
  &.animation {
    animation: blink 1s;
  }

  &.stop-animation {
    animation: none;
  }
}

.element-wrapper,
.favourite {
  > a,
  button {
    display: flex;
    align-items: center;
    max-width: 400px;
  }

  app-fav-popup {
    .standard-price-without-promotion {
      width: 120px;
      text-align: right;
      margin-left: auto;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fixed {
  .navbar-wrapper {
    @media screen and (min-width: $resolutionDesktop) {
      .header-icon {
        app-account-popup,
        app-fav-popup,
        app-cart-popup {
          top: 4em;
        }
      }
      .cart-items-number {
        margin-left: 8px;
      }
    }
  }
}

.cart-items-number {
  @include tiny-text();
  @include font-style-semi();
  color: $mainColor;
  display: block;
  @media screen and (min-width: $resolutionDesktop) {
    margin-left: 10px;
  }
}

.second-step {
  font-size: 16px;
}

.navbar-search {
  .input-search-wrapper {
    label {
      pointer-events: none;
    }
  }
}

.hover-success {
  &:hover {
    a {
      color: $success;
    }
  }
}

nav .right-part .panel-above .element-wrapper {
  @media screen and (min-width: $resolutionDesktop) {
    &:nth-child(1) {
      .link {
        padding-left: 0;
      }
    }

    &:nth-child(5) {
      .link {
        padding-right: 0;
      }
    }

    &:hover {
      z-index: 9;
    }
  }
}
