swiper {
  &:hover {
    .swiper-button-next:not(.swiper-button-disabled),
    .swiper-button-prev:not(.swiper-button-disabled) {
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 18px 0 rgba($heading, 0.5);

      &:hover {
        background: $textLink;
      }
    }

    .swiper-button-disabled {
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 18px 0 rgba($heading, 0.1);
    }
  }

  &.slider-main {
    display: flex;
    flex-direction: column;
  }

  .swiper-pagination {
    order: 2;
    position: static;
    margin-top: 16px;
  }

  .swiper-button-next {
    right: 50px;

    @media screen and (min-width: $resolutionHalfHd) {
      right: 115px;
    }

    &::after {
      content: "\e005";
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    &.swiper-button-disabled {
      opacity: 1;
      cursor: pointer;
      pointer-events: initial;

      &:hover {
        background: rgba($textLink, 0.35);
        box-shadow: 0 0 18px 0 rgba($heading, 0.1);
      }
    }
  }

  .swiper-button-prev {
    left: 50px;

    @media screen and (min-width: $resolutionHalfHd) {
      left: 115px;
    }

    &::after {
      content: "\e003";
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    top: -53px;
    bottom: 0;
    margin: auto;
    height: 56px;
    width: 76px;
    background: rgba(white, 0.5);
    border-radius: 50px;
    transition: 0.2s ease-out;

    @media screen and (min-width: $resolutionDesktop) {
      display: flex;
    }

    &::after {
      color: $heading;
      font-size: 16px;
      font-family: ArteNawigacja;
    }

    &:hover {
      background: $success;
      transition: 0.2s ease-out;
      box-shadow: 0 0 18px 0 rgba($heading, 0.5);
      pointer-events: initial;

      &::after {
        color: white;
      }
    }
  }

  .swiper {
    overflow: inherit;

    .swiper-pagination {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: static;
      flex-wrap: wrap;
      @media screen and (min-width: $resolutionDesktop) {
        margin-top: 10px;
      }
    }

    .swiper-pagination-handle {
      outline: none !important;
      pointer-events: all !important;
      border: none !important;
      opacity: 1 !important;
    }

    .swiper-pagination-bullet {
      background-color: $border !important;
      border: none !important;
      opacity: 1 !important;
      pointer-events: none !important;

      &:hover {
        outline: none !important;
        box-shadow: $focus !important;
      }

      &:focus {
        outline: none !important;
        box-shadow: $focus !important;
      }
    }

    .swiper-pagination-bullets {
      bottom: 0 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: $heading !important;
      width: 40px;
      height: 8px;
      border-radius: 8px;
    }

    .swiper-pagination-bullet-last {
      border: none !important;
      margin: 2px 8px !important;
    }
  }

  &.wykladziny {
    app-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.swiper-pagination,
.swiper-pagination-gallery-main-product {
  .swiper-pagination-handle {
    outline: none !important;
    pointer-events: all !important;
    border: none !important;
    opacity: 1 !important;
  }

  &.swiper-pagination-bullets-dynamic {
    .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next, .swiper-pagination-bullet-active-prev, .swiper-pagination-bullet-active-prev-prev {
      transform: none;
    }
    .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-prev {
      &::after {
        transform: scale(0.66);
      }
    }
    .swiper-pagination-bullet-active-next-next, .swiper-pagination-bullet-active-prev-prev {
      &::after {
        transform: scale(0.33);
      }
    }
  }

  .swiper-pagination-bullet {
    border: none;
    opacity: 1;
    pointer-events: all;
    margin: 0 2px !important;
    padding: 20px;
    background-color: transparent;
    position: relative;

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: $border;
      display: block;
      border-radius: 50%;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:hover,
    &:focus {
      outline: none !important;
      &::after {
        box-shadow: $focus !important;
      }
    }

    &.swiper-pagination-bullet-last {
      margin-bottom: 2px !important;
    }
  }

  .swiper-pagination-bullets {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: transparent;
    width: 56px;
    &::after {
      background-color: $heading !important;
      width: 40px;
      height: 8px;
      border-radius: 8px;
    }
  }

  .swiper-pagination-bullet-last {
    border: none !important;
    margin: 2px 8px !important;
  }
}

.swiper-pagination-gallery-main-product {
  .swiper-pagination-bullet {
    margin: 40px 12px 0 !important;
  }
}

.swiper.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  margin-left: auto;
  margin-right: auto;
  transform: unset;
  height: auto !important;
  .swiper-pagination-bullet-active {
    width: auto;
    &::after {
      width: 20px;
    }
  }
  .swiper-pagination-bullet-active-main {
    transform: scale(1.25, 1);
  }
}

.swiper-button-next,
.swiper-button-prev {
  user-select: none;
}
