.isServer{
  .menu{
    @media screen and (min-width: $resolutionDesktop) {
      //margin-left:24px;
    }
  }
  app-menu{
    .menu{
      //margin-left:0.25em;
    }
    @media screen and (min-width: $resolutionDesktop) {
      display: block;
    }
  }

}


