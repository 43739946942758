.input {
  border-radius: 24px;
  border: 1px solid $border;
  width: 100%;
  color: $label;
  position: relative;
  padding: 18px 1.5em 0;
  display: block;
  background-color: $primaryBackground !important;
  font-size: 16px;
  background-clip: padding-box;
  line-height: 26px;

  &::placeholder {
    font-weight: $light-weight;
    color: $label;
  }

  &.input-search-after {
    width: 100%;
    height: 46px;
  }

  &:hover {
    box-shadow: 0px 0px 0px 1px $border !important;
  }

  &.active {
    .label {
      font-size: 10px;
      line-height: 18px;
    }

    .placeholder {
      color: $bodyText;
    }
  }

  &:focus {
    .label {
      font-size: 10px;
      line-height: 18px;
    }

    .placeholder {
      color: $bodyText;
    }

    box-shadow: $focus !important;
    outline: none !important;
  }

  &.disabled {
    background-color: $inactiveElement !important;
    color: $bodyText !important;
    opacity: 1;
    pointer-events: none;
    user-select: none;
  }

  &.no-label {
    height: 50px;
    padding: 2px 1.5em 0;
  }
}

.input-search-wrapper {
  .ai.ai-search {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    font-size: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

input,
textarea {
  font-size: initial;
}

.input-search-wrapper {
  position: relative;
  background-color: $primaryBackground;

  label {
    cursor: text;
  }
}

textarea {
  font-size: 16px;
  line-height: 26px;
  font-family: Jost, sans-serif;

  &::placeholder {
    font-weight: $light-weight;
    color: $label;
  }
}

input {
  font-family: Jost, sans-serif;
}

.input {
  &::placeholder {
    color: transparent;
  }

  &.placeholder-visible {
    &::placeholder {
      color: $bodyText;
    }
  }

  color: $bodyText;
  font-weight: $light-weight;
}

.form-label {
  top: 10.5px;
  font-size: 16px;
  line-height: 26px;
  left: 25px;
  position: absolute;
  z-index: 1;
  display: block;
  transition: 0.2s;
  font-weight: $light-weight;
  color: $label;

  &.is-Empty {
    top: 10.5px;
    font-size: 16px;
    line-height: 26px;
    left: 25px;
  }
}

.form-label {
  &:not(.is-Empty) {
    top: 8px;
    font-size: 10px;
    line-height: 18px;
  }
}

input.label-up ~ label {
  top: 6px !important;
  font-size: 10px !important;
  line-height: 18px !important;
  left: 25px;
}

input.input.ng-invalid.ng-dirty.ng-touched {
  box-shadow: 0px 0px 0px 2px $mainColor !important;
}
