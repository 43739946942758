.placeholder-text {
  display: block;
  background-color: #F1F1F1;
  height: 1em;
  margin: 1em;
  width: 30%;
}

.placeholder-image {
  width: 221px;
  height: 100%;
  background: #f1f1f1;
  @media screen and (min-width: $resolutionDesktop) {
    width: 250px;
  }
}
