@import "core/variables";

.title-wrapper {
  .font-style-semi {
    @include heading3();
    @media screen and (min-width: $resolutionDesktop) {
      @include heading2();
      margin-bottom: 0.5em;
    }
  }
}

.text-slider-wrapper {
  position: absolute;
  z-index: 10000;
  bottom: 30px;
  left: 40px;

  .heading7 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .white-after {
    color: white;
  }
}

.secondary-title {
  @include font-style-light();
  @media screen and (min-width: $resolutionDesktop) {
    @include heading4();
  }
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .link-to-more {
    display: none;
    height: 30px;
    @media screen and (min-width: $resolutionDesktop) {
      display: block;
    }
  }
}

@media screen and (min-width: $resolutionDesktop) {
  .slider-wrapper-elem {
    height: 600px;

    .description-wrapper {
      height: 600px;

      p {
        padding-right: 1em;
      }
    }

    .image-wrapper {
      img {
        max-width: none !important;
      }
    }
  }
}

.isWithBanner {
  .slider-wrapper-elem {
    overflow: hidden !important;
  }

  .description-wrapper {
    .block-name {
      display: none;
    }
  }
}

.slider-wrapper {
  @media screen and (min-width: $resolutionDesktop) {
    overflow: hidden;
  }
}

.tooltip-banner {
  box-shadow: 0 0 18px rgba(34, 34, 34, 0.2);
  height: 50px;
  width: fit-content;
  padding: 0 20px;
  border-radius: 8px;
  z-index: 1;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  background: white;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.slider-wrapper-elem {
  .description-inline-wrapper {
    width: 100%;
    display: inline-flex;
    cursor: pointer;
  }

  .minus-index {
    z-index: -10;
  }

  .description-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    order: 0;
    margin-right: 1em;
    padding-left: 1.5em;
    position: absolute;
    bottom: 48px;
    @media screen and (min-width: $resolutionTablet) {
      background-color: rgba(255, 255, 255, 0.95);
    }
    @media screen and (min-width: $resolutionDesktop) {
      position: static;
      background-color: rgba(34, 34, 34, 0.04);
      width: 655px;
      height: 600px;
      margin-right: 2em;
      padding-left: 3em;
    }
    @media screen and (min-width: $resolutionFullHd) {
      padding-left: 4em;
    }

    .heading4,
    .text-link {
      margin: 0;
    }

    .text-link {
      margin-top: 0.75em;
      margin-bottom: 1em;
      width: fit-content;
      @media screen and (min-width: $resolutionDesktop) {
        padding-right: inherit;
        margin-top: 0;
      }
      max-width: 200px;
    }

    .block-caption {
      margin: 16px auto 0;
      @media screen and (min-width: $resolutionDesktop) {
        font-weight: $semi-weight;
        font-size: 40px;
        line-height: 48px;
        @include heading2();

        &.main-slider {
          line-height: 58px !important;
          font-size: 48px !important;
          font-weight: 600 !important;
          @include no-uppercase();
          margin-top: 30px;
          margin-bottom: 35px;
        }
      }
      @media screen and (min-width: $resolutionHalfHd) {
        font-weight: $semi-weight;
        font-size: 48px;
        line-height: 58px;
      }
    }

    .block-name {
      @media screen and (min-width: $resolutionDesktop) {
        display: block;
      }
    }
  }

  .image-wrapper {
    width: auto;
    height: 270px;
    overflow: hidden;
    @media screen and (min-width: $resolutionTablet) {
      width: auto;
      height: auto;
    }

    img {
      max-width: 100%;

      &.double-width {
        max-width: none;
      }
    }
  }

  .description-wrapper {
    max-width: 371px;
    @media screen and (min-width: $resolutionDesktop) {
      max-width: 600px;
      min-width: 510px;
    }
  }
}

.no-uppercase {
  text-transform: inherit;
}

.server-view {
  margin-bottom: 2.2em;

  swiper {
    .swiper-wrapper {
      .slider-wrapper-elem {
        .description-wrapper {
          @media screen and (max-width: $resolutionDesktopMinus) {
            width: 35%;
          }
          @media screen and (max-width: $resolutionTabletMinus) {
            width: 280px;
          }
        }

        display: none;
      }
    }
  }
}

.flex-wrapper-checkbox {
  border: none;
  margin-top: 12px;

  &.disabled {
    opacity: 0.5;
    cursor: default !important;

    input:hover ~ .checkmark {
      border-width: 1px;
    }
  }

  .font-style-light {
    white-space: normal;
  }
}

.single-element {
  padding-left: 1em;
}

.price {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.swiper-pagination {
  margin-top: 8px;
}

.image-main-slider {
  background-color: $inactiveElement;
  width: 100% !important;
  position: relative;
  overflow: hidden;
  width: 50vw;
  height: 50vh;
  picture,
  img {
    width: 100%;
    height: 100%;
  }
}

.top-slider {
  .swiper-wrapper {
    height: 446px;
    @media screen and (min-width: $resolutionDesktop) {
      height: 600px;
    }
  }
}

.swiper {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: $resolutionDesktop) {
  .c-stay-at-home-main-banner {
    position: relative;

    .promo-information {
      //display:block;
      position: relative;
      margin-top: 20px;

      //margin-bottom: 10px !important;
    }

    p {
      margin: 0px;
      font-size: 18px;
    }

    .col-name {
      width: 50%;
      line-height: 22px;
      font-size: 18px;
      float: left;
    }

    .col-promo-name {
      width: 50%;
      line-height: 24px;
      font-size: 24px;
      font-weight: 600;
      text-transform: inherit;
      float: right;
      text-align: left;
      position: absolute;
      bottom: 10px;
      left: 146px;
    }

    .discount-value {
      font-weight: 600;
      font-size: 36px;
      margin: 0;
      padding: 0;
    }

    .discount-tag {
      position: absolute;
      bottom: -35px;
      color: #fff;
      font-size: 21px;
      font-weight: bold;
    }
  }

  .stay-at-home__subtitle {
    position: absolute;
    color: #fff;
    left: 200px;
    bottom: 15px;

    span {
      font-weight: 600;
    }
  }

  .stay-at-extend-promo {
    position: absolute;
    color: #fff;
    left: 159px;
    bottom: -1px;
    font-size: 12px;
  }

  .stay-at-extend-promo-popup {
    position: relative;
    bottom: -1px;
    font-size: 10px;
    color: #000;
    font-weight: 100;
    margin: 15px 0;
  }
}

@media screen and (min-width: $resolutionDesktop) {
  .c-stay-at-home-main-banner {
    position: relative;

    .promo-information {
      display: block;
      top: -60px;
      position: relative;
      margin-bottom: 10px !important;
    }

    p {
      line-height: 42px;
      font-size: 32px;
      margin: 0px;
      position: relative;
      top: -60px;
    }

    .col-name {
      width: 50%;
      line-height: 42px;
      font-size: 32px;
      float: left;
    }

    .col-promo-name {
      width: 50%;
      line-height: 58px;
      font-size: 48px;
      font-weight: 600;
      text-transform: inherit;
      float: right;
      position: absolute;
      bottom: -1px;
    }

    .discount-value {
      line-height: 80px;
      font-size: 80px;
      font-weight: 600;
      positon: relative;
      top: -60px;
      text-transform: inherit;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .discount-tag {
      font-weight: $semi-weight;
      font-size: 60px;
      line-height: 60px;
      bottom: 80px;
      position: absolute;
    }
  }

  .stay-at-home__subtitle {
    position: absolute;
    color: #fff;
    z-index: 2;
    left: 25px;
    bottom: 38px;
    font-size: 60px;
    font-weight: 300;
    font-size: 60px;
    bottom: 70px;

    span {
      font-weight: 600;
    }
  }

  .stay-at-extend-promo {
    position: absolute;
    color: #fff;
    z-index: 2;
    left: 243px;
    bottom: 56px;
    font-size: 20px;
    font-weight: 300;
  }
}
