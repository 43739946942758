.textarea {
  background-color: $primaryBackground;
  border: 1px solid $border;
  border-radius: 1em;
  padding: 1.5em;
  color: $label;
  width: calc(100% - 3em);
  display: block;
  resize: none;
  &:hover {
    border: 2px solid $border;
    padding: calc(1.5em - 1px);
  }
  &:focus {
    box-shadow: $focus;
    outline: none;
  }
  &.disabled {
    background-color: $inactiveElement;
  }
  &.error {
    border: 2px solid $mainColor;
    &:hover {
      padding: 1.5em;
    }
  }
}
