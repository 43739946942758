.cms-wrapper {
  min-height: 300px;

  .image-wrapper-loader {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: $resolutionDesktop) {
    margin-bottom: 128px;
    position: relative;
  }

  .content-left {
    .cms-block-heading {
      @include heading5();
      height: auto;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin: 18px 0 20px;
      @media screen and (min-width: $resolutionTablet) {
        margin-top: 2px;
        margin-bottom: 16px;
      }
    }
  }

  .separator-big {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 1px;
    margin: 32px -1em;
    background-color: $specialColorForOneOFSeparatorGray;
  }

  .content-right {
    margin-top: 38px;

    .cms-heading {
      @include heading3();
      margin-bottom: 16px;
      @include font-style-semi();
      @media screen and (min-width: $resolutionTablet) {
        @include heading2();
      }
    }

    .cms-content {
      .foot-box {
        > h3 {
          @include heading6();
        }

        > h2 {
          @include heading5();
        }
      }
    }
  }
}

.header {
  h2 {
    @include heading5();
    @media screen and (min-width: $resolutionTablet) {
      @include heading3();
    }
  }

  p {
    margin: 16px 0;
    @media screen and (min-width: $resolutionTablet) {
      margin: 24px 0;
    }
  }

  table tbody tr th {
    text-align: left;
  }

  margin-top: 24px;
  margin-bottom: 48px;
  @media screen and (min-width: $resolutionTablet) {
    margin-top: 48px;
  }
}

.cms-wrapper .wrapper .content-right {
  margin-top: 0;
  @media screen and (min-width: $resolutionDesktop) {
    margin-left: 32px;
    &.content-right-full {
      margin-left: 0;
    }
  }

  .cms-heading {
    @include heading2();
    margin-top: 14px;
    margin-bottom: 42px;
  }

  .cms-content {
    * {
      text-align: left !important;
    }

    .regulamin-success-link {
      color: $success;
    }

    .regulamin-wzor-info {
      margin-top: 16px;
      margin-bottom: 40px;
      color: $bodyText;
    }

    .regulamin-wzor-odstapienia {
      text-align: left;
      margin-top: 40px;
      margin-bottom: 50px;
    }

    .regulamin-stopka {
      @include font-style-light();
      font-style: italic;
      color: $label;
      margin-top: 35px;
    }

    h3 {
      @include heading6();
      font-weight: 400;
      margin-bottom: 40px;
    }

    h2 {
      @include heading3();
      margin: 40px -10px 32px;
      padding-left: 5px;
    }

    a {
      word-break: break-word;
    }

    ol {
      list-style: decimal;
      margin: 0 0 1.5em;
      padding: 0;
      counter-reset: item;

      > li {
        padding: 0;
        text-indent: 0;
        list-style-type: none;
        counter-increment: item;
        margin: 0 0 32px;
        @include font-style-light();

        &:before {
          display: inline-block;
          font-weight: bold;
          text-align: left;
          margin-right: 0.25em;
          content: counter(item) ".";
        }

        ol {
          margin-top: 32px;
          counter-increment: item-1;
          padding-left: 10px;
          @media screen and (min-width: $resolutionTablet) {
            padding-left: 20px;
          }

          li {
            &:before {
              content: counters(item, ".") ".";
            }

            ol {
              counter-increment: item-1;
              padding-left: 10px;
              @media screen and (min-width: $resolutionTablet) {
                padding-left: 20px;
              }

              li {
                &:before {
                  content: counters(item, ".") ".";
                }

                ol {
                  li {
                    &:before {
                      content: counters(item, ".") ".";
                    }
                  }
                }
              }
            }
          }
        }

        ul li {
          margin-left: 22px;
          padding-right: 4px;
          margin-top: 24px;
          margin-bottom: 24px;

          &:nth-child(1) {
            margin-top: 18px;
          }

          @include font-style-light();

          &:before {
            content: "\2022";
            color: $success;
            font-weight: bold;
            display: inline-block;
            margin-right: 1em;
            margin-left: -1em;
          }
        }
      }
    }

    ul {
      li {
        &:before {
          content: "\2022";
          color: $success;
          font-weight: bold;
          display: inline-block;
          margin-right: 1em;
        }

        margin: 12px 0;
        font-weight: 300;
      }
    }

    hr {
      display: none;
    }
  }
}

@media screen and (min-width: $resolutionDesktop) {
  .cms-wrapper .wrapper {
    .background-cms-page-wrapper {
      display: flex;
    }

    .content-left {
      width: 256px;
      display: flex;
      min-width: 256px;
      flex-wrap: wrap;
      padding-right: 32px;

      .cms-block-heading {
        display: none;
      }
    }
  }
}

.animationBackground {
  border-radius: 10px;
  animation: change-background 3s ease;
}

@keyframes change-background {
  0% {
    background: white;
  }

  20% {
    background: white;
  }

  40% {
    background: #cddaed;
  }

  50% {
    background: #cddaed;
  }

  100% {
    background: white;
  }
}

.bordered-table {
  border-spacing: 0;

  .min-width {
    @media screen and (min-width: $resolutionTablet) {
      min-width: 230px;
    }
  }

  .border-top {
    border-top: 2px solid $success;
  }

  .border-bottom {
    border-bottom: 2px solid $success;
  }

  .border-left {
    border-left: 2px solid $success;
  }

  .border-right {
    border-right: 2px solid $success;
  }

  .padding-cell {
    padding: 8px;
  }
}

.responsive-table {
  overflow-x: auto;
}
