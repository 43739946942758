.toggle {
  width: 3em;
  height: 1.5em;
  border: 1px solid $border;
  border-radius: 12px;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: border-bottom-color 0.2s, border-left-color 0.2s,
    border-right-color 0.2s, border-top-color 0.2s;
  &:after {
    position: absolute;
    content: "";
    border-radius: 45px;
    background-color: $border;
    height: 0.75em;
    width: 0.75em;
    top: 5px;
    left: 6px;
    transition: background-color 0.2s;
  }
  label {
    margin-left: 1em;
    color: $bodyText;
    font-weight: $light-weight;
  }
  &:hover {
    box-shadow: 0px 0px 0px 1px $border;
  }
  &.active-toggle {
    border: 1px solid $heading;
    transition: border-bottom-color 0.2s, border-left-color 0.2s,
      border-right-color 0.2s, border-top-color 0.2s;
    &:after {
      right: 6px;
      left: inherit;
      background-color: $heading;
      transition: background-color 0.2s;
    }
    &:hover {
      box-shadow: 0px 0px 0px 1px $heading;
    }
    label {
      color: $heading;
      font-weight: $semi-weight;
    }
    &.disabled {
      border: 1px solid $border;
      background-color: $inactiveElement;
      &:after {
        background-color: $border;
      }
    }
  }
  &:focus {
    outline: none;
    border: $focus-border;
    box-shadow: $focus;
  }
  &.disabled {
    background-color: $inactiveElement;
  }
}
