.tag {
  margin: 0 2em;
  border: 1px solid $border;
  border-radius: 16px;
  padding: 1em 3em 1em 1em;
  display: inline-block;
  align-items: center;
  color: $success;
  font-size: 12px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
  position: relative;

  &:after {
    content: "\e008";
    font-family: ArteNawigacja;
    color: $success;
    position: absolute;
    width: 10px;
    height: 10px;
    font-size: 10px;
    right: 1em;
    top: calc(50% - 14px / 2);
  }
  &:hover {
    box-shadow: 0 0 0 1px $border;
  }
  &:focus {
    outline: none;
    box-shadow: $focus;
    border: $focus-border;
  }
  &.disabled {
    background-color: $inactiveElement;
    color: $border;
    &:after {
      color: $border;
    }
  }
  &.no-close {
    padding: 7px 2em;
    border: 1px solid transparent;
    border-radius: 16px;
    background-color: $inactiveElement;
    @include font-style-semi();
    color: $bodyText;
    margin-top: 10px !important;
    margin-bottom: 1px;
    margin-left: 1px;
    margin-right: 24px;
    &.active {
      color: $success;
      border: 1px solid $border;
      background-color: $primaryBackground;
    }
    &:after {
      display: none;
    }
  }
}
