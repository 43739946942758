.button {
  border: none;
  user-select: none;
  border-radius: 24px;
  width: 224px;
  height: 48px;
  line-height: initial;
  display: flex;
  font-size: 16px;
  font-weight: $semi-weight;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
  transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
  &:focus {
    outline: none;
    box-shadow: $focus;
  }
  &.small {
    border-radius: 18px;
    height: 36px;
    &.circle {
      width: 36px;
      height: 36px;
    }
  }
  &.middle-small {
    &.circle {
      width: 42px;
      height: 42px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  &.circle {
    width: 48px;
    height: 48px;
    &.no-border {
      border: none;
    }
    &.empty-inside {
      background-color: transparent;
    }
    &.border-success {
      border: 2px solid $success;
    }
  }
  &.big {
    border-radius: 32px;
    height: 64px;
    &.circle {
      width: 64px;
      height: 64px;
    }
  }
  &.disabled {
    cursor: default;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &.loading {
    position: relative;
    span {
      position: absolute;
      width: 32px;
      height: 32px;
      margin: auto;
      z-index: 1;
      border-radius: 40px;
      animation: rotation 1.5s linear infinite;
      background-image: url("/assets/images/loader.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 24px 24px;
    }
  }

  &.error {
    border-color: $mainColor;
    background-color: $mainColor !important;
    -webkit-transition: background-color 0.3s, width 0.3s, border-width 0.3s;
    transition: background-color 0.3s, width 0.3s, border-width 0.3s;
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 40px;
      background: $mainColor;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: "\e008";
        font-family: ArteNawigacja;
        font-size: 24px;
        color: #000;
      }
    }
    &.loading {
      span {
        position: relative;
      }
    }
  }

  &.success {
    border-color: $success;
    background-color: $success;
    -webkit-transition: background-color 0.3s, width 0.3s, border-width 0.3s;
    transition: background-color 0.3s, width 0.3s, border-width 0.3s;
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 40px;
      background: $success;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: "\e017";
        font-family: ArteNawigacja;
        font-size: 24px;
        color: #fff;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-top: 5px;
  }
}

.primary-button {
  background-color: $success;
  color: $primaryBackground;
  font-weight: $semi-weight;
  &:hover {
    background-color: $primary-button-hover;
  }
  &:active {
    background-color: rgb(87, 156, 140);
  }
  &.disabled {
    background-color: $border;
  }
}

.secondary-button {
  background-color: $primaryBackground;
  border: 2px solid $heading;
  font-weight: $semi-weight;
  color: $heading;
  -webkit-transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
  transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
  &:hover {
    border: 2px solid $hover-light;
    border-radius: 24px $hover-light;
    background-color: $hover-light;
    color: $primaryBackground;
    @media screen and (min-width: $resolutionDesktop) {
      color: $primaryBackground !important;
    }
    &:focus {
      box-shadow: $focus;
    }
  }
  &.disabled {
    background-color: $primaryBackground;
    color: $border;
    border-color: $border;
    &:hover {
      background-color: $primaryBackground;
      color: $border !important;
      border-color: $border;
    }
  }
  &:active {
    color: $primaryBackground;
    border: 2px solid $heading;
    background-color: $heading;
  }
  &.loading {
    .loader {
      background-image: url("/assets/icons/loader_black.svg");
    }
  }
  &.error {
    border: 0;
  }
}

.tertiary-button {
  background-color: $primaryBackground;
  border: 2px solid $border;
  color: $heading;
  &.circle
  {
    width: 40px;
    height: 40px;
    border: 1px solid $inactiveElement;
    border-color: #DADCE0;
    .ai-facebook
    {
      color: #0866FF;
    }
  }
  &:hover {
    background-color: $inactiveElement;
    border-radius: 24px $hover-light;
    border: 2px solid $inactiveElement;
  }
  &:active {
    background-color: $border;
    border: 2px solid $border;
  }
  &.disabled {
    background-color: $primaryBackground;
    color: $border;
    border-color: $border;
  }
}

//radio buttons
.radio-button-wrapper {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.radio-button {
  background-color: $primaryBackground;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 90px;
  -webkit-appearance: none;
  position: relative;
  margin: 0 1em 3px 0.5em;
  outline: none;
  cursor: pointer;
  border: 1px solid $primaryBackground;
  input {
    cursor: pointer;
  }
  &:hover {
    &:after {
      content: "";
      position: absolute;
      border: 2px solid $border;
      width: 22px;
      height: 22px;
      border-radius: 90px;
    }
  }
  label {
    font-size: 16px;
    line-height: 26px;
    color: $bodyText;
  }
  &:after {
    content: "";
    position: absolute;
    border: 1px solid $border;
    width: 24px;
    height: 24px;
    border-radius: 90px;
    margin-top: 0;
    margin-left: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:focus {
      outline: none;
      border: $focus-border;
      border-radius: 90px;
    }
  }
  &.active {
    position: relative;
    &:hover {
      &:after {
        border: 1px solid $heading;
        width: 24px;
        height: 24px;
      }
    }
    &:focus {
      &:after {
        border: $focus-border;
        box-shadow: $focus;
      }
    }
    &:before {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 90px;
      background-color: $heading;
      content: "";
    }
    &.disabled {
      background-color: $inactiveElement;
      border-radius: 4px;
      .dot {
        background-color: $border;
      }
    }
  }
  &.disabled {
    &:after {
      background-color: $inactiveElement;
      border-radius: 4px;
    }
    label {
      color: $bodyText;
    }
  }
}

//checkbox

.flex-wrapper-checkbox {
  position: relative;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 24px;
    height: 24px;
    margin: 0;
    z-index: 1;
    cursor: pointer;
  }
  input:hover ~ .checkmark {
    border: 2px solid $border;
  }
  input:checked ~ .checkmark,
  input.checked ~ .checkmark {
    background-color: $heading;
    border: none;
    &::before {
      content: "\e017";
      font-family: ArteNawigacja;
      font-size: 14px;
      color: #fff;
    }
    .tick {
      display: block;
      position: absolute;
    }

    &.disabled {
      background-color: $border;
      label {
        font-style: "semi";
        color: $border;
      }
    }
    &:after {
      position: absolute;
      width: 13.25px;
      height: 9.16px;
      left: calc(50% - 13.25px / 2 - 0.21px);
      top: calc(50% - 9.16px / 2 + 0.52px);
      background-color: $primaryBackground;
    }
    label {
      font-style: "semi";
    }
  }

  input:focus ~ .checkmark {
    box-shadow: $focus;
    outline: none;
  }

  input:disabled ~ .checkmark {
    background-color: $inactiveElement;

    label {
      font-style: "light";
      color: $inactiveElement;
    }
  }

  &.gray {
    input:hover ~ .checkmark {
      border: 1px solid $border;
    }

    .checkmark {
      background-color: $inactiveElement;
      cursor: default;
      border: none;
    }

    input:checked ~ .checkmark {
      background-color: $border;
    }
  }

  .checkmark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border: 1px solid $border;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.error {
      border: 1px solid $mainColor;
    }
    .tick {
      display: none;
    }
  }
  label {
    color: $bodyText;
    font-size: 16px;
    line-height: 26px;
  }
  &.disabled {
    input:checked ~ .checkmark {
      background-image: none;
      &.wielokolorowy {
        background-image: none !important;
      }
    }
  }
  p {
    padding-right: 8px;

    &.expanded {
      max-height: 200px;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1799deg);
  }
}
