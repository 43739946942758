//@import  '~@'//"./node_modules/nouislider/distribute/nouislider.css",
@import '~nouislider/dist/nouislider.css';
/*slider filter*/
.noUi-horizontal {
  height: 3px !important;
  border: 0 !important;
  background: #CCCCCC !important;
  .noUi-connect {
    background: #222222 !important;
  }
}

.noUi-origin .noUi-handle {
  border-radius: 45px !important;
  background: white !important;
  border: 2px solid black !important;
  width: 1.25em !important;
  height: 1.25em !important;
  box-shadow: none !important;
  cursor: pointer !important;
  top: -10px !important;
  &:hover {
    outline: none !important;
    box-shadow: $focus !important;
  }
  &:active {
    outline: none !important;
    background-color: #222222 !important;
    box-shadow: none !important;
  }
  &:focus {
    outline: none !important;
    box-shadow: $focus !important;
  }
  &:before {
    content: none !important;
  }
  &:after {
    content: none !important;
  }
  .noUi-touch-area {
    border: 1px solid transparent;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    width: auto;
    height: auto;
  }
}
