@import "core/font";
@import "core/icons";
@import "core/variables";
@import "core/facade";
@import "core/mixins";
@import "core/typography";
@import "components/all_components";
@import "layouts/footer";
@import "layouts/navbar";
@import "layouts/slider";
@import "layouts/menu";
@import "photoswipe/photoswipe.css";
@import "abbrevations";
@import "noUISlider";
@import "slider-swiper";
@import "swiper/css/bundle";

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body {
    background-color: #ffffff !important;
  }
}

html.scroll-blocked.intercom-mobile-messenger-active > body {
  position: fixed !important;
}

.kiosk-mode {
  .intercom-lightweight-app-launcher {
    display: none !important;
  }
}

.slider-template-big-products {
  .discount {
    left: -7% !important;
  }
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  footer {
    transition: opacity 0.2s;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

[hidden] {
  display: none !important;
}

@media (hover: none) {
  button {
    color: inherit;
  }
}

body {
  font-family: Jost, sans-serif;
  margin: 0;
  min-height: 100%;
  background-color: #fff;

  .container {
    max-width: 1920px;
    position: relative;
    margin: 0 auto;
    padding-left: $mobileContainerPadding;
    padding-right: $mobileContainerPadding;

    @media screen and (min-width: $resolutionTablet) {
      padding-left: $tabletContainerPadding;
      padding-right: $tabletContainerPadding;
    }

    @media screen and (min-width: $resolutionDesktop) {
      padding-left: $desktopContainerPadding;
      padding-right: $desktopContainerPadding;
    }

    @media screen and (min-width: $resolutionFullHd) {
      padding-left: $fullHdContainerPadding;
      padding-right: $fullHdContainerPadding;
    }
    .full-width-section {
      margin-left: -$mobileContainerPadding;
      margin-right: -$mobileContainerPadding;

      @media screen and (min-width: $resolutionTablet) {
        margin-left: -$tabletContainerPadding;
        margin-right: -$tabletContainerPadding;
      }

      @media screen and (min-width: $resolutionDesktop) {
        margin-left: -$desktopContainerPadding;
        margin-right: -$desktopContainerPadding;
      }

      @media screen and (min-width: $resolutionFullHd) {
        margin-left: -$fullHdContainerPadding;
        margin-right: -$fullHdContainerPadding;
      }
    }
    .fit-width-section {
      @media screen and (min-width: $resolutionDesktop) {
        padding: 0 48px;
      }
      @media screen and (min-width: $resolutionHalfHd) {
        padding: 0 190px;
      }
    }
  }

  .content-panel {
    margin: 24px 0 0;
    color: $heading;

    @media screen and (min-width: $resolutionTablet) {
      margin: 32px 0;
    }
    @media screen and (min-width: $resolutionDesktop) {
      position: relative;
      margin: -248px auto 0 auto;
      padding: 44px;
      background-color: white;
    }
    @media screen and (min-width: $resolutionHalfHd) {
      padding: 44px 144px;
    }
  }

  .link {
    .image-wrapper {
      cursor: pointer;
    }
  }

  .image-wrapper {
    cursor: default;
    &.full-width-section {
      display: flex;
      background-color: $placeholder-light;
      picture {
        display: block;
        position: static;
      }
      img {
        display: block;
        //width: 100%;
        //height: 100%;
        object-fit: cover;
      }
    }
  }
}

.overlay-shown {
  body {
    margin-right: 16px;
  }
}

li {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: $heading;
}

button {
  font-family: inherit;
}

p {
  margin: 0.75em 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

img {
  user-select: none;
}

.success-link {
  color: $success;
  cursor: pointer;
}

.no-margin {
  margin: 0 !important;
}

.relative {
  position: relative;
}

.no-wrap {
  white-space: nowrap !important;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;

  .wrapper {
    .under-wrapper {
      margin: auto;
      border-radius: 0px;
      @media screen and (min-width: $resolutionDesktop) {
        border-radius: 8px;
      }
    }
  }

  &.loaded {
    background-color: rgba(0, 0, 0, 0.25);
    transition: background-color 0.2s;
  }
}

.image-wrapper {
  cursor: pointer;
}

//reset autofill color{
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 9999px white;
  transition: background-color 600000s 0s, color 600000s 0s;
}

@keyframes onAutoFillStart {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@keyframes onAutoFillCancel {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

input:-webkit-autofill {
  // Expose a hook for JavaScript when autofill is shown
  // JavaScript can capture 'animationstart' events
  animation-name: onAutoFillStart;

  // Make the background color become yellow really slowly
  transition: background-color 2s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
  // Expose a hook for JS onAutoFillCancel
  // JavaScript can capture 'animationstart' events
  animation-name: onAutoFillCancel;
}

//collapsing block
.shorter,
.longer {
  p {
    margin: 0;
  }
}

app-simple-block {
  p {
    margin: 0;
  }
}

app-collapsing-block {
  .shorter,
  .longer {
    .expand,
    .collapse {
      pointer-events: all;
      color: $success;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}

ng-scrollbar {
  --scrollbar-padding: 0px !important;
  --scrollbar-track-transition: none !important;
  --scrollbar-thumb-transition: none !important;
  .ng-scrollbar-wrapper, .ng-scroll-viewport-wrapper, .ng-scroll-layer, .ng-scroll-viewport {
    position: absolute !important;
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
  }
}

//swiper fix
// .swiper-slide {
//   height: auto !important;
// }

aside ul {
  margin-bottom: 0;

  li {
    display: flex;
    max-width: 256px;

    a {
      margin-bottom: 23px;
      @media screen and (min-width: $resolutionTablet) {
        margin-bottom: 17px;
      }
      @include font-style-light();
      @media screen and (min-width: $resolutionDesktop) {
        @include heading6();
        margin-bottom: 24px;
        @media screen and (max-height: 900px) {
          margin-bottom: 20px;
          line-height: 26px;
        }
        color: $heading;
      }
    }

    &:nth-last-child(1) {
      a {
        margin-bottom: 0;
      }
    }
  }
}

label {
  user-select: none;
  -webkit-user-select: none;
}

::-webkit-input-placeholder {
  user-select: none;
}

.toggle-password-button {
  position: absolute;
  top: 1px;
  bottom: 0;
  margin: auto;
  height: fit-content;
  right: 45px;
  font-size: 12px;
  user-select: none;
}

.ng-invalid.ng-touched.ng-dirty {
  input.input.ng-touched.ng-dirty.ng-invalid ~ .toggle-password-button {
    color: $mainColor;
  }

  input.input.ng-touched.ng-dirty.ng-invalid ~ label.form-label {
    color: $mainColor;
  }
}

.text-error {
  color: $errorColor;
}

.text-bold {
  font-weight: 900;
}

.text-center {
  text-align: center;
}

.text-thin {
  font-weight: 100;
}

.text-left {
  text-align: left !important;
}

.empty-state-main {
  height: 100%;
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.additional-info {
  color: $heading !important;
  text-align: right;
  margin-left: auto;
  margin-right: 32px;

  &.dropdown-price {
    @include font-style-light();
    color: $bodyText;
  }

  &.bold {
    @include font-style-semi();
  }
}

.price-unit {
  margin-left: 4px;
  color: $heading !important;
}

.preload-icons {
  height: 0;
  visibility: hidden;

  img {
    height: 0;
  }
}

.gm-style-iw-d {
  overflow: auto !important;
}

.gm-style-iw-tc {
  &:after {
    top: -60px !important;
  }
}

.gm-style-iw {
  button {
    top: 0 !important;
    right: 0 !important;
    width: 36px !important;
    height: 36px !important;

    img {
      width: 18px !important;
      height: 18px !important;
    }
  }
}

.gm-style-iw.gm-style-iw-c {
  outline: none;
  padding: 0 !important;
  box-shadow: $shadow2;
  top: -60px !important;
}

.info-window-wrapper .left-side {
  width: 273px;
  overflow: hidden;
}

@media screen and (max-width: $resolutionDesktopMinus) {
  app-overlay.full {
    .loading {
      top: 0 !important;
      height: 116px;
      z-index: 14 !important;
    }
  }
}

.grey {
  color: $border !important;
}

.swiper-slide-duplicate {
  display: none;
}

.product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.circle {
  margin-right: 0.5em;

  .heart-icon {
    width: 18px;
    height: 18px;
  }
}

.heart-icon {
  @media screen and (min-width: $resolutionDesktop) {
    margin-left: 8px;
    width: 14px;
    margin-bottom: 2px;
  }
}

.gm-style-iw-d {
  @media screen and (max-width: $resolutionTabletMinus) {
    max-width: 240px !important;
  }
}

.flex {
  display: flex;
}

.placeholder {
  background: $inactiveElement;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

//
//
//
//
//Universal Preboot configuration
//
//app-product-slider, .insparanz-slider {
//  visibility: hidden;
//}
//
//app-tile-rwd-box {
//  .tile-rwd {
//    overflow: hidden;
//    height: 0;
//  }
//}
//
//.complementary-section {
//  background-color: rgba(210, 210, 210, 0.2);
//
//  .swiper-container {
//    visibility: hidden;
//  }
//}
//
//app-product-picture .product-pictures-gallery {
//  visibility: hidden;
//}
//
//.menu {
//  visibility: hidden;
//  @media screen and (min-width: $resolutionDesktop) {
//    visibility: visible;
//  }
//}
//
//.isServer {
//  .tile-rwd {
//    display: none;
//  }
//
//  app-product-slider, .insparanz-slider {
//    visibility: visible;
//  }
//
//  app-product-picture .product-pictures-gallery, .menu {
//    visibility: visible;
//  }
//}
//
//.isBrowser {
//  app-product-slider, .insparanz-slider {
//    visibility: visible;
//  }
//
//  app-tile-rwd-box .tile-rwd {
//    height: auto;
//  }
//
//  .complementary-section {
//    background-color: $primaryBackground;
//
//    .swiper-container {
//      visibility: visible;
//    }
//  }
//
//  app-product-picture .product-pictures-gallery, .menu {
//    visibility: visible;
//  }
//}

a {
  &.collapse {
    padding: 2px 8px 8px 2px;
  }

  &.expand {
    padding: 2px 8px 8px 2px;
  }
}

.notification-bar {
  display: block;
  margin: 1em 0;
  @media screen and (min-width: $resolutionTablet) {
    margin: 1em 24px 64px;
  }
  @media screen and (min-width: $resolutionDesktop) {
    margin: 28px 64px 48px;
  }
  @media screen and (min-width: $resolutionHalfHd) {
    margin: 28px 112px 48px;
  }
}

.flex-wrapper-checkbox {
  p {
    position: relative;
  }
  .checkbox-text {
    flex: 1;
    .ng-scroll-viewport {
      padding-right: 8px;
    }
  }
}

.display-initial {
  display: initial !important;
}

.mobile-nav-open {
  height: 100vh;
}

button::-moz-focus-inner {
  border: 0;
}

.insparanz {
  width: 242px !important;
  height: 242px !important;
  overflow: hidden;

  img {
    height: auto !important;
  }

  @media screen and (min-width: $resolutionTablet) {
    width: 269px !important;
    height: 269px !important;
  }
  @media screen and (min-width: $resolutionDesktop) {
    width: 400px !important;
    height: 400px !important;
  }
}

.main-content {
  padding: 15px 0px;
}
