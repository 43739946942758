.tooltip{
  background-color: $primaryBackground;
  filter: drop-shadow(rgba(34, 34, 34, 0.4) 0 0px 8px);
  //todo: ie do zrobienia
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  color: $label;
  position: relative;
  //todo: min width chyba niepotrzebne, do analizy
  min-width: 12em;
  min-height: 8em;
  margin: 1em;
  &:after{
    background-color: $primaryBackground;
    width: 12px;
    height: 12px;
    position: absolute;
    content:'';
  }
  &.top{
    &:after{
      transform: rotate(45deg);
      left:calc(50% - 12px/2);
      top:-6px;
    }
  }
  &.left{
    &:after{
      transform: rotate(45deg);
      top:calc(50% - 12px/2);
      left:-6px;
    }
  }
  &.right{
    &:after{
      transform: rotate(45deg);
      position: absolute;
      top:calc(50% - 12px/2);
      right:-6px;
    }
  }
  &.bottom{
    &:after{
      transform: rotate(45deg);
      left:calc(50% - 12px/2);
      bottom:-6px;
    }
  }
}
