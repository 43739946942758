@import "./variables";

.component-shape1 {
  border-radius: 8px;
}

.component-shape2 {
  border-radius: 0;
}

.separator1 {
  margin-bottom: 1em !important;
}

.separator-one-half {
  margin-bottom: 1.5em !important;
}

.separator2 {
  margin-bottom: 2em !important;
}

.separator3 {
  margin-bottom: 3em !important;
}

.separator4 {
  margin-bottom: 4em !important;
}

.separator5 {
  margin-bottom: 5.5em !important;
}

.separator6 {
  margin-bottom: 8em !important;
}

.separator7 {
  margin-bottom: 12em !important;
}

.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6,
.body-text,
.small-text,
.tiny-text,
.caption-text {
  font-family: Jost;
  color: $bodyText;
  &:not(.body-text, .small-text, .tiny-text, .caption-text) {
    color: $heading;
  }
}

.heading1 {
  line-height: 7px;
  font-size: 5px;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.heading2 {
  line-height: 42px;
  font-size: 32px;
  font-weight: 600;
}

.heading3 {
  line-height: 34px;
  font-size: 24px;
}

.heading4 {
  line-height: 34px;
  font-size: 24px;
  font-weight: 400;
}

.heading5 {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
}

.heading6 {
  line-height: 30px;
  font-size: 20px;
  font-weight: 400;
}

.heading-super-big {
  font-size: 48px;
  line-height: 58px;
  font-weight: $semi-weight;
}

.body-text {
  line-height: 26.5px;
  font-size: 16px;
  font-weight: 400;
  color: $bodyText;
}

.small-text {
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
}

.tiny-text {
  line-height: 16px;
  font-size: 12px;
  font-weight: 300;
}

.caption-text {
  line-height: 18px;
  font-size: 10px;
  font-weight: 400;
}

.hover {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 10px;
}

.focus {
  box-shadow: $focus;
}

.active-font {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 10px;
}

.logo {
  color: $mainColor;
}

.circle-percent {
  background-color: $inactiveElement; //background-radius
  background-color: $success; //active
}

.outlet-box {
  border: 2px solid $mainColor;
  box-sizing: border-box;
}

.main-color {
  color: $mainColor !important;
}

.separator-gray {
  margin: 2px 0;
  height: 0;
  border-top: 1px solid #f1f1f1;

  &.differentColor {
    border-top: thin solid #e1e1e1;
  }

  &.border {
    border-top: 1px solid #cccccc;
  }

  &.full-height {
    margin: 2px 0;
    border-top: thin solid #cccccc;
  }

  @media screen and (min-width: $resolutionDesktop) {
    margin: 2px -3em;
  }
}

.full-width-section {
  .separator-gray {
    @media screen and (min-width: $resolutionDesktop) {
      margin: 2px -4em;
    }
  }
}

.font-style-light {
  font-weight: $light-weight;
}

.font-style-book {
  font-weight: $book-weight;
}

.font-style-semi {
  font-weight: $semi-weight;
}

.color-body-text {
  color: $bodyText;
}

.color-inactive {
  color: $inactiveElement;
}

.color-label-text {
  color: $label;
}

.color-success {
  color: $success !important;
}

.text-link {
  font-weight: $semi-weight;
  color: $heading;
  line-height: 30px;
  font-size: 20px;
  position: relative;
  display: inline;
  cursor: pointer;
  user-select: none;
  a {
    color: $heading;
  }
  &.white-after {
    &:after {
      background-color: $primaryBackground;
    }
  }
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $heading;
    transition: background-color 0.2s;
  }
  &:hover {
    &:after {
      background-color: $success;
      transition: background-color 0.2s;
    }
  }
  &:active {
    &:after {
      display: none;
    }
  }
  &:focus {
    text-shadow: $focus;
    outline: none;
    &:after {
      box-shadow: $focus;
      outline: none;
    }
  }
}
