.under-wrapper {
  transform: translateY(2000px);
  transition: transform 0.2s;

  &.loaded {
    transform: translateY(0);
  }
}

.popup-wrapper {
  background-color: $primaryBackground;
  box-shadow: $shadow2;
  cursor: default;
  border-radius: 0.5em;
  padding: 32px;
  box-sizing: border-box;
}

.modal-wrapper {
  &.loaded {
    .wrapper:not(.scroll) {
      animation: overflow 0.6s;
      overflow-y: auto;

      @keyframes overflow {
        0% {
          overflow-y: hidden;
        }

        100% {
          overflow-y: auto;
        }
      }
    }
  }

  .ai-cancel {
    display: block;
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
}
